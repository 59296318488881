<template>
    
    
    <b-modal v-model="isShown" centered hideHeader @hidden="$emit('close')" no-close-on-backdrop>
        <div class="step1" v-if="step===1">
            <h6 class="text-center mb-2 py-2">BUY TICKET</h6>
            <small class="small fs-px-11 text-muted">Ticket Purchase Quantity</small>
            <div class="input-group ticket_count my-2">
                <!-- <span class="rounded-0 input-group-text" :class="{'active':ticket_quantity>=limit}" @click="decrement"><i class="mx-auto far fa-minus"></i></span> -->
                <span class="rounded-0 input-group-text" :class="{'active':ticket_quantity>1}" @click="decrement"><i class="mx-auto far fa-minus"></i></span>
                <input type="number" class="form-control border-0 bg-yellow-5 text-center fw-bold" v-model="ticket_quantity" @input="checkLimit">
                <!-- <span class="rounded-0 input-group-text" :class="{'active':ticket_quantity<limit}" @click="increment"><i class="mx-auto far fa-plus"></i></span> -->
                <span class="rounded-0 input-group-text" 
                :class="{'active': !upper_limit}" 
                @click="increment">
                    <i class="mx-auto far fa-plus"></i>
                </span>
            </div>

            <small class="small fw-light fs-px-11 text-muted">*(Total Number of Tickets : {{info.limit?info.limit:'No limit'}})</small>
            <hr>
            <div class="fs-px-12">
                <div class="flex-between-center mb-3">
                    <span>Price Per Ticket</span>
                    <span class="text-danger fw-bold">{{ info.d_price }} {{info.deal_coin}}</span>
                </div>
                <div class="flex-between-center mb-3">
                    <span>Total Purchase Amount</span>
                    <span class="text-danger fw-bold">{{ CheckAmount(info.d_price , ticket_quantity) }} {{info.deal_coin}}</span>
                </div>
                <div class="flex-between-center">
                    <span>My Balance</span>
                    <span class="text-danger fw-bold">{{ CheckBalance(info.deal_coin,my_balance) }} {{info.deal_coin}}</span>
                </div>
            </div>
            <hr>
            <div class="fs-px-11">
                <b>NOTIFICATION</b>
                <p class="pt-2 text-muted">* * Buyer can decide to cancel purchase before the deal is closed ; {{seller_cancel_fee}}% cancellation fee of total purchase amount will be charged to the Buyer
                <br><br>
                * If the deal is expired or the cancelled by Seller no cancellation fee will be charged on Buyer side and purchase amount will be fully refunded.
                <br><br>
                * Winning prize will be automatically transacted to winner's wallet after deducting {{seller_success_cancel_fee}}% service charge.
                </p>


            </div>
        </div>
        <div class="step2" v-if="step===2">
            <h6 class="text-center mb-2 py-2">
                <i class="fas fa-chevron-left text-yellow-1 position-absolute start-0 ms-3" @click="step=1"></i>
                Purchase Summary</h6>
            <div class="fs-px-12">
                <div class="flex-between-center mb-3">
                    <span>What will I be making</span>
                    <span class="fw-bold">{{ info.price }} {{info.coin}}</span>
                </div>
                <div class="flex-between-center mb-3">
                    <span>Purchased ticket quantity</span>
                    <span class="fw-bold">{{ ticket_quantity }}</span>
                </div>
                <div class="flex-column mb-1">
                    <span>Purchased ticket numbers</span>

                    <ul class="ticket_num_box">
                        <li class="d-flex">
                            <div class="badge bg-yellow-2 text-black"  v-for="(item, index) in hold_list" :key="index">{{item}}</div>
                            <span class="me-1"  v-if="success_list.length > 0">+</span>    
                        </li>
                        
                        <li class="d-flex" v-if="success_list.length > 0">
                            <div class="badge bg-yellow-4 text-black" v-for="(item, index) in success_list" :key="'buy'+index">{{item}}</div>
                        </li>


                        
                    </ul>
                </div>
            </div>
            <div class="fs-px-11 mb-3">
                <p class="pt-2 text-muted">* The ticket numbers are strictly given in accordance to the order of purchase, and may not be chosen by buyers. 
                <br><br>
                * If an attempted purchase is cancelled, the ticket numbers will be marked as used and the next available number will be assigned.
                <br><br>
                * Based on verified formula ‘remainder calculation’, all numbers have an equal chance of winning.</p>
            </div>
            <div class="fs-px-12">
                <div class="flex-between-center mb-3">
                    <span>Total Amount of Purchase</span>
                    <span class="fw-bold">{{CheckAmount(info.d_price,ticket_quantity)}} {{info.deal_coin}}</span>
                </div>
                <div class="flex-between-center">
                    <span>Chance of winning</span>
                    <span class="fw-bold">{{CheckTotalPer(success_list,info.total)}}% + <b class="text-danger">({{CheckTotalPer(hold_list,info.total)}}%)</b></span>
                </div>
            </div>
        </div>
        
        <template #modal-footer>
            <div class="d-flex w-100">
            <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="Cancel()">CANCEL</button>
            <button class="py-3 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50" @click="purchase" v-if="step===1">PURCHASE</button>
            <button class="py-2 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50" v-else @click="complete">CONFIRM<br>PURCHASE</button>
            </div>
        </template>

        <Loading v-if="loading"/>
    </b-modal>

</template>
<script>
import { BModal } from 'bootstrap-vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import Loading from '@/components/common/Loading.vue'
export default {
    components: {
        BModal,Loading
    },
    data(){
        return{
            my_balance:this.info.balance,
            ticket_quantity:1,
            loading : false,
            // 최대 구매수량
            perMax:100,
            // 발행 티켓수량
            limit:this.info.limit,
            seller_cancel_fee : '',
            seller_success_cancel_fee : '',
            step:1,
            tickets:[],
            success_list:[],
            hold_list:[],
            // front ui
            isShown: this.show,
            upper_limit: false,
            deal_coin : this.info.deal_coin,
            common_coin : [],
        }
    },
    mounted(){
        this.GetSellerFee();
        this.GetTicketInfo()
        this.GetCoinInfo()
    },
    methods:{
        GetCoinInfo(){
            this.$http.post('/member/transaction/GetCoinInfo',{}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.common_coin = body.list;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        CheckBalance(coin,balance){
            let w_up = '';
            for (const el of this.common_coin) {
                if(coin ==el.symbol){
                    w_up = el.w_up;
                }
            }
            const balance_t = balance+'';
            if(balance_t > 0 ){
                const float_arr = balance_t.split('.');
                let price = '';
                if(float_arr.length == 2 ){
                    let f_string = float_arr[1]+ '';
                    price = float_arr[0] +'.'+f_string.substring(0, w_up);
                }else{
                    price = balance;
                }
                return price;
            }else{
                return balance_t;
            }
        },
        
        CheckTotalPer(list,total){
            const length = list.length;
            
            if(length ==0){
                return 0;
            }else{
                const s = exactMath.mul(length,100);
                const t = exactMath.div(s,total);
                const per = exactMath.ceil(t,-1);

                return per
            }

        },
        Cancel(){
            
            const coin = this.info.deal_coin;
            const room_code = this.info.code;

            const body = {room_code,coin};

            
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/dashboard/CancelTicket',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$emit('close');
                            this.step=1;
                            this.ticket_quantity=1;
                        }else if(res.data.code =="9999"){
                            this.$emit('close')
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        GetSellerFee(){
            const body = {};

            
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/purchase/GetSellerFee',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.seller_cancel_fee = body.seller_cancel_fee;
                            this.seller_success_cancel_fee = body.seller_success_cancel_fee;
                        }else if(res.data.code =="9999"){
                            this.$emit('close')
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        GetTicketInfo(){
            const coin = this.info.deal_coin;
            const room_code = this.info.code;

            const body = {room_code,coin};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/purchase/GetTicketInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.perMax = body.info.max;
                            this.my_balance = body.info.balance;
                        }else if(res.data.code =="9999"){
                            this.$emit('close')
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        BuyTicket(){
            const room_code = this.info.code;
            const ticket_count = this.ticket_quantity;

            const body = {room_code,ticket_count};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.loading = true;
            this.$http.post('/member/purchase/PurchaseTicket',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            // this.tickets = body.list;
                            this.success_list = body.success_list;
                            this.hold_list = body.hold_list;
                            this.ticket_quantity = body.quantity;
                            this.step=2;
                        }else if(res.data.code =="100"){
                            this.$emit('close')
                            this.$alert('The seller has cancelled the deal.').then(()=>{
                                this.$router.go()
                            })
                        }else if(res.data.code =="9999"){
                            this.$emit('close')
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
            
        },
        ConfirmBuy(){
            const room_code = this.info.code;
            const ticket_count = this.ticket_quantity;

            const body = {room_code,ticket_count};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/member/purchase/ConfirmBuy',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$emit('close')
                            this.$alert('Purchase Complete').then(()=>{
                                window.location.href='/mydeal/buy/participate';
                            })
                            
                        }else if(res.data.code =="100"){
                            this.$emit('close')
                            this.$alert('The seller has cancelled the deal.').then(
                                ()=>{
                                    this.$router.go();
                                }
                            )
                            
                            
                        }else if(res.data.code =="300"){
                            this.$emit('close')
                            this.$alert('This Deal is closed, unable to make a purchase.').then(
                                ()=>{
                                    this.$router.go();
                                }
                            )
                            
                            
                        }else if(res.data.code =="9999"){
                            this.$emit('close')
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
            this.step=2;              
        },
        decrement() {
            this.ticket_quantity--;
            this.checkLimit();
        },
        increment() {
            this.ticket_quantity++;
            this.checkLimit();
        },
        checkLimit(){

            const priceData = this.info.d_price;
            if (this.ticket_quantity <= 0) {
                this.$pp({
                    msg: 'You need to purchase at least 1 ticket', 
                    is_confirm: false, 
                    auto_hide:true
                })
                this.ticket_quantity=1;
            }

            if(this.ticket_quantity*priceData*1>this.my_balance*1 ){
                this.$pp({
                    msg: 'Insufficient balance', 
                    is_confirm: false, 
                    auto_hide:true
                })
                this.ticket_quantity = parseInt(Math.floor(this.my_balance / priceData));
            }
            
            if(this.perMax){
                
                const limit =  exactMath.sub(this.info.total,this.info.count)
                if(this.ticket_quantity*1  > limit*1){
                    this.$pp({
                        msg: 'Your purchase exceeds purchase limit', 
                        is_confirm: false, 
                        auto_hide:true
                    })
                    this.ticket_quantity = limit;
                }else{
                    if(this.ticket_quantity*1 > this.perMax*1){
                        this.$pp({
                            msg: 'Your purchase exceeds purchase limit', 
                            is_confirm: false, 
                            auto_hide:true
                        })
                        this.ticket_quantity = this.perMax;
                    }
                }
                
            }else{
                // 없을때 최대 티켓한도
                if(this.ticket_quantity*1  > this.info.count*1){
                    this.$pp({
                        msg: 'Your purchase exceeds purchase limit', 
                        is_confirm: false, 
                        auto_hide:true
                    })
                    this.ticket_quantity = this.info.count;
                }else{
                    if(this.ticket_quantity*1 > this.limit*1){
                        this.$pp({
                            msg: 'Your purchase exceeds total number of tickets', 
                            is_confirm: false, 
                            auto_hide:true
                        })
                        this.ticket_quantity = this.limit;
                    }
                }
                
            }

        },
        purchase(){

            const priceData = this.info.d_price;

            if(this.ticket_quantity==0){
                this.$pp({
                    msg: 'You need to purchase at least 1 ticket', 
                    is_confirm: false, 
                    auto_hide:true
                })
            }else if(this.ticket_quantity*priceData*1>this.my_balance*1){
                this.$pp({
                    msg: 'Insufficient balance', 
                    is_confirm: false, 
                    auto_hide:true
                })
            }else{
                this.BuyTicket();
                
            }
        },
        complete(){
            this.ConfirmBuy();
            
        },
        CancelHold(){
            const room_code = this.info.code;

            const body = {room_code};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/purchase/CancelTicket',{req});
        },
        CheckAmount(a,b){
            const info = exactMath.mul(a,b);
            return info
        },

    },
    watch:{
        show(){
            this.isShown = this.show;
        },
        ticket_quantity(){
            const priceData = this.info.price;
            if(this.ticket_quantity >= this.limit){
                this.upper_limit = true;

            }else if(this.perMax != null && this.ticket_quantity >= this.perMax){
                this.upper_limit = true;

            }else if( (parseInt(this.ticket_quantity)+1)*priceData>=this.my_balance){
                this.upper_limit = true;
            }else{
                this.upper_limit = false;
            }
        }
    },
    props:{
        show:{
            type:Boolean,
            required: true
        },
        buyTF:{
            type:Object,
            required:false,
            default: function(){
                return{
                    status:false,
                }
            }
        },
        info:{
            type:Object,
            default: function(){
                return{
                    price:1,
                    // 코인 가격, 임시값. PurchasePopup에 prop으로 :info="item" 데이터가 전달 안됐을 경우.
                }
            }
        }
    },
    beforeUnmount() {
        this.CancelHold()
    }
}
</script>
<style lang="scss" scoped>
.ticket_count{
    span.input-group-text{
        width: 36px;
        padding: 0;
        background-color: #EDEDED;
        border: 3px solid #aaa;
        color: #aaa;
    }
    span.input-group-text.active{
        background-color: #FFB800;
        border: 3px solid #9A0058;
        color: #9A0058;
    }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import common_js from "./assets/js/common.js";
import alert_js from "./assets/js/alert.js";
import axios from "./axios.js"
import 'regenerator-runtime/runtime'

Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.use(common_js);
Vue.use(alert_js);
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
import VueQrcodeReader from "vue-qrcode-reader";
import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);
Vue.use(VueQrcodeReader);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

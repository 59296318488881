<template>
  <div id="app">
    <router-view/>
    <!-- <Loading /> -->
  </div>
</template>

<script>
import Loading from '@/components/common/Loading.vue'

export default {
  components: {
    Loading
  },
}
</script>
<style lang="scss">
@import 'assets/css/all.css'; //폰트어썸
@import '~bootstrap/dist/css/bootstrap.min.css'; //부트스트랩
@import '~bootstrap-vue/dist/bootstrap-vue.css'; //부트스트랩
@import 'assets/css/custom-bootstrap.scss';
@import 'assets/css/common.scss';

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Noto+Sans:wght@300;400;500;600;700&display=swap');

body{
  background-color: #36001F;
  font-family:'Noto Sans', 'Noto Sans KR', sans-serif;
  font-weight: 400;
  font-size: 15px;
}

#app>div{
  min-height: 100vh;
  padding-bottom: 157.88px;
  position: relative;

  // 반응형
  max-width: 768px;
  margin: 0 auto;
}

// 모달 footer
.modal-footer {
  border-top: none;
}

// select
.form-select {
  background-image:url('./assets/img/layout_icon/arrow_down.svg');
}

.content_wrap{ //헤더 푸터 제외 컨텐츠
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  min-height: calc(100vh - 55px - 128.41px);
}

.deal-list{
  flex-wrap: wrap;
  justify-content: space-between;
  li.item{
    width: 48%;
    margin-bottom: 13px;
  }
  // 텍스트 제목이 2줄이 되었을 경우 사이즈가 안맞는 문제
  .custom-info{
    display: flex;
    flex-direction: column;
    h6{
      flex: 0 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
  }

  .coin-info{
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
    img{
      height: 40px;
    }
  }
  .progress{
    border: 1px solid #aaa;
    height: 5px !important;
  }
  .ticket-info{
    color:#5B5B5B;
    border-top: dashed 2px #FFB800; 
  .ticket{
    width: 106px;
    letter-spacing: 1px;
  }
  }
  &.horizontal{
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 8px;
    &::-webkit-scrollbar { 
      display: none !important; // 윈도우 크롬 등
    }
    li{
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}

.ticket_num_box{
  // 3줄 스크롤되는
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 5px;
    max-height: 65px;
    overflow-y: auto;
    .badge{
        display: block;
        padding: 5px 10px;
        // height: 22px;
        height: fit-content;
        margin-right: 5px;
        margin-bottom: 5px;
    }
}
// 티켓 모양 css
.ticket{
  // overflow: hidden;
  text-align: center;
  position: relative;
  &::before{
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FFF7E3;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  &::after{
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FFF7E3;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%,-50%);
  }
}
</style>

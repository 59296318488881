<template>
    <div class="basic_pp full_hight bottom_up" v-if="pp">
        <div class="dimm bg-body-i bg-opacity-50" @click="pp=false"></div>
        <div class="content bg-burgundy-0 rounded-0 w-100 mh-vh-100 text-white">
            <div class="header position-sticky flex-between-center pb-3">
                <h5 class="text-truncate">{{title}}</h5>
                <i class="fal fa-times p-2 pointer"  @click="pp=false"></i>
            </div>
            <div class="body overflow-auto text-start" ref="basic_pp_body">
                <pre class="mb-10">
                    {{terms[idx]}}
                </pre>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            pp: false,
            idx: null,
            terms: [`1. Introduction
These Terms and the documents referred to below (the "Terms") apply to the use of the current website (the "Website") and related or linked services (the "Services"). These Terms and Conditions contain important information about your rights and obligations in connection with the use of this Website and form binding legal agreements between our customers (the "Customers") and us (the "Website"), thus you should carefully review them. By using this website or accessing this Service, you agree to be bound by these Terms and Conditions, whether you are a customer or a registered user with an account ("the Account"), which may be published from time to time. If you do not agree to these terms and conditions, you should be prohibited from accessing the service and using the website.

2. General Terms We reserve the right to revise and amend the Terms and Conditions (including any documents mentioned and linked below) at any time. You should visit this page regularly to review the Terms and Conditions. The amendments are binding and effective as soon as they are posted on this website. If you object to these changes, you should stop using the service immediately. If you continue to use the website after these posts, we will notice that your consent will be bound by the revised terms and conditions. Any deals that have not been closed before the changed terms and conditions are subject to the existing terms and conditions. When the Onecoinbuy Purchase Terms and Conditions are updated, the platform notifies you and asks for confirmation of your consent.

3. Your Obligations 
You acknowledge that at all times when accessing the Website and using the Service: 
3.1. You are 18 years of age or older, or you are a statutory age at which dealing raffles and gaming activities are permitted under any law or jurisdiction applicable to you. We reserve the right to request your proof of age document at any time.
3.2. You have legal capacity and can enter into legally binding contracts with us. If you do not have legal capacity, you should not access the Website or use the Services.
3.3.  You are aware that access to the website and all products available on the website may be considered illegal in certain countries. We cannot verify the legality of the service in all jurisdictions, so we are responsible for determining whether your website access and use comply with applicable laws in your country and guarantee that OCB service is not illegal. For a variety of legal or commercial reasons, we do not allow customers living in a specific jurisdiction to open or use an account, including but not limited to :
Afghanistan, Algeria, Antigua & Barbuda, Australia, Austria, Azerbaijan, Belarus, Bahrain, Belgium, Bhutan, Brunei, Bulgaria, Canada, Central African Republic, Chad, Côte d'Ivoire, Croatia, Cuba, Cyprus, Czech Republic, Democratic People’s Republic of Korea (North Korea), Democratic Republic of the Congo, Denmark, Eritrea, Estonia, Finland, France, Georgia, Germany, Greece, Guinea-Bissau, Hungary, Iran, Iraq, Iceland, Israel, Ireland, Italy, Jordan, Kuwait, Latvia, Lebanon, Liberia, Libya, Lithuania, Luxembourg, Malta, Montenegro, Myanmar, Netherlands, Netherlands Antilles (Curacao, Sint Maarten, Bonaire, Sint Eustatius, and Saba, Aruba), Norway, Oman, Palestine, Poland, Portugal, Qatar, Republic of the Congo, Reunion, Romania, Russia, Saint Martin, Saudi Arabia, Serbia, Sierra Leone, Slovak Republic, Slovenia, Somalia, Spain, Sweden, Switzerland, Syrian Arab Republic, Sudan, Suriname, Trinidad and Tobago, Turkey, Ukraine, United Kingdom, United Arab Emirates, United States (and its dependencies, military bases and territories i.e. U.S. Minor Outlying Islands, U.S. Virgin Islands), Vanuatu, Yemen.
or other restricted jurisdictions ("Restricted Jurisdiction") that are reported by us from time to time. By using the Website you confirm you are not a resident in a Restricted Jurisdiction.
3.4. When attempting to register an account or using the Website, it is the responsibility of the user to verify whether OCB service is legal in that particular jurisdiction. 
3.5. You are an authorized user of the payment method that you use, and any funds that are in your account and used within the service are legal.
3.6. You must make all payments to us in good faith and do not take any action that could cause any third party to reverse any payments made.
3.7. When purchasing raffle tickets you may not win and lose some or all of your asset deposited to the Service in accordance with these Terms and you will be fully responsible for that loss. 
3.8. When purchasing raffle tickets you must not use any information obtained in breach of any legislation in force in the country in which you were when the purchase was made. 
3.9. When issuing a deal as a seller, if you input the wrong number causing loss of your profit or asset, you will be fully responsible for that loss
3.10. You do not act on behalf of other parties or for commercial purposes, but only for yourself as an individual in your capacity.
3.11. You shall not attempt to manipulate the market or elements within the Services in a manner that will adversely affect the Services or our integrity. 
3.12. You must generally act in good faith in relation to the use of the Service at all times and for all bets made using the Service. 
3.13. While using Onecoinbuy at any time you consent to provide us only true, real, and objective data on default and when We request it. 
3.14. You understand the volatility of the cryptocurrency market and acknowledge the possibility of currency/currencies prices changing while you wait for withdrawals or while our risk department processes withdrawals.
3.16. You're a mentally competent person and you don't get gambling addiction. If someone with this problem enters the site – he or she is the only one responsible.
3.17. You agree not to use any third-party software in any of the subdivisions of Onecoinbuy as well as not to abuse any deals/functioning bugs found on the platform.
3.18. You guarantee that, from the moment of registration, all information you have provided to us during the validity of this Agreement is true, complete and accurate, and that you shall immediately notify us of any change in such information.
3.19. You understand that information entered at the time of registration and during service use cannot be changed at your decision. You must contact support to change the information. For any change of information, Onecoinbuy may request all documents to pass through KYC at its own discretion.
3.20. You guarantee that you will not use our services while in your jurisdiction prohibiting you from playing online raffle/lottery..
3.21.You also guarantee that:
- You participate in the Services on your own behalf and not on the behalf of any other person;
- You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from us;
- You are solely responsible for any applicable taxes which may be payable on cryptocurrency awarded to you through your using the Service.

4. Restricted use 
4.1. You must not use the Service: 
4.1.1. If you are under the age of 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) or if you are not legally able to enter into a binding legal agreement with us or you acting as an agent for, or otherwise on behalf, of a person under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you); 
4.1.2. If you reside in a country in which access to online lottery to its residents or to any person within such country is prohibited; 
4.1.3. To collect nicknames, e-mail addresses, and/or other information of other Customers by any means (for example, by sending spam, other types of unsolicited emails, or the unauthorized framing of, or linking to, the Service); 
4.1.4. To disrupt or unduly affect or influence the activities of other Customers or the operation of the Service generally; 
4.1.5. To promote unsolicited commercial advertisements, affiliate links, and other forms of solicitation which may be removed from the Service without notice; 
4.1.6. In any way which, in our reasonable opinion, could be considered as an attempt to: (i) cheat the Service or another Customer using the Service; or (ii) collude with any other Customer using the Service in order to obtain a dishonest advantage; 
4.1.7. To scrape our odds or violate any of our Intellectual Property Rights; 
4.1.8. For any unlawful activity whatsoever; 
4.1.9. Creating multiple accounts for the purpose of collusion, fraud, Service abuse, and/or any kind of Service manipulation is prohibited and may result in account closure. 
4.1.10. Any use of software which gives any advantage, include software which betting statistics software, analyzes the probability of a particular combination falling out or programs that help the user make a decision when playing or use strategies aimed at unfairly obtaining winnings or gaining an advantage over the deals is prohibited. Winnings and funds which received from these actions will be regarded as illegal. Onecoinbuy reserves the right to withhold these funds, including the user's deposit funds. Onecoinbuy reserve the right, after withholding such funds, to block the account without the right to restore. Withholding funds and blocking account carried out at Onecoinbuy's sole discretion and cannot be appealed by the user.
If the amount of illegally gained funds is greater than the amount that is placed on the deposit account of such a user, Onecoinbuy can recover these funds from the user, including claims.
4.2. You cannot sell or transfer your account to third parties, nor can you acquire a user account from a third party. 
4.3. We may immediately terminate your Account upon written notice to you if you use the Service for unauthorized purposes. We may also take legal action against you for doing so in certain circumstances. 
4.4. Restrictions to the use of the third-party products are set on the side of the product provider and are not under the control of the Onecoinbuy side.

5. Registration 
You agree that at all times when using the Service: 
5.1. We reserve the right to refuse to accept a registration application from any applicant at our sole discretion and without any obligation to communicate a specific reason. 
5.2. Before using the Service, you must personally complete the registration form and read and accept these Terms. We may require you to become a verified Customer which includes passing certain checks. You may be required to provide valid proof of identification and any other document as it may be deemed necessary. This includes but is not limited to, a picture ID (copy of passport, driver's license, or national ID card) and a recent utility bill listing your name and address as proof of residence. We reserve the right to suspend wagering or restrict Account options on any Account until the required information is received. This procedure is done in accordance with the applicable gaming regulation and the anti-money laundering legal requirements. Additionally, you will need to fund your Service Account using the payment methods set out on the payment section of our Website. 
5.3. If You register via email You have to provide accurate contact information, inclusive of a valid email address (“Registered Email Address”), and update such information in the future to keep it accurate. It is your responsibility to keep your contact details up to date on your Account. Failure to do so may result in you failing to receive important Account related notifications and information from us, including changes we make to these Terms. We identify and communicate with our Customers via their Registered Email Address. It is the responsibility of the Customer to maintain an active and unique email account, to provide us with the correct email address, and to advise the Company of any changes in their email address. Each Customer is wholly responsible for maintaining the security of his Registered Email Address to prevent the use of his Registered Email Address by any third party. Company shall not be responsible for any damages or losses deemed or alleged to have resulted from communications between Company and the Customer using the Registered Email Address. We will immediately suspend your Account upon written notice to you to this effect if you intentionally provide false or inaccurate personal information. 
5.4. You are only allowed to register one Account with the Service. Accounts are subject to immediate closure if it is found that you have multiple Accounts registered with us. This includes the use of representatives, relatives, associates, affiliates, related parties, connected persons, and/or third parties operating on your behalf. 
5.5. To ensure your financial worthiness and verify your identity, we may ask you to provide additional personal information such as your first and last name or to use a third party data provider as we deem necessary. If we need to obtain additional personal information through third party sources, we will inform you of the data obtained.
5.6. Password for the Service confidential. Provided that the Account information requested has been correctly supplied, we are entitled to assume that deals, purchases, deposits, and withdrawals have been made by you. We advise you to change your password on a regular basis and never disclose it to any third party. You are solely responsible for protecting your password and any failure to do so shall be at your sole risk, expense and responsibility. You may log out of the Service at the end of each session. If you think your account has been breached and Information is being misused by a third party, or your Account has been hacked into, or your password has been discovered by a third party, you have to notify us immediately. You must notify us if your Registered Email Address has been hacked into, we may, however, require you to provide additional information/ documentation so that we can verify your identity. We will immediately suspend your Account once we are aware of such an incident. In the meantime, you are responsible for all activity on your Account including third-party access, regardless of whether or not their access was authorized by you. 
5.7. You must not at any time broadcast nor share any content to another Customer or any other party. 
5.8. When registering, you will receive the possibility to use all currencies available on the website. Those will be the currencies of your deposits, withdrawals, and trading. 
You can use all currencies available on the website. But, if you use currencies and/or win currencies that have low liquidity on most of the crypto exchanges or are presented in small quantities on the crypto market, we reserve the right to pay out your winnings in USDT. In the described case, you agree that Onecoinbuy, at its sole discretion, might process and payout your winnings in USDT at the current Binance exchange rate at the period of your winnings.
5.9. We are under no obligation to open an Account for you and our website sign-up page is merely an invitation to treat. It is entirely within our sole discretion whether or not to proceed with the opening of an Account for you and, should we refuse to open an Account for you, we are under no obligation to provide you with a reason for the refusal. 
5.10. Upon receipt of your application, we may be in touch to request further information and/ or documentation from you in order for us to comply with our regulatory and legal obligations. 
5.11. A user can have only one account. All duplicate user accounts will be counted as “duplicates” and will be closed immediately. Any winnings, bonuses, and returns that you have gained from the duplicate account we call into question, and also, we may reclaim it. You will return to us all demanded funds, which have been withdrawn from your duplicate account. 
Identification
5.12. We will verify all transactions to prevent money laundering and other illegal activities. If you agree to the Terms, you authorize us to perform personal identity verification in accordance with any requirements or requirements of third parties (including regulatory authorities) to verify your identity and contact details. do. In some cases, we may need to contact you and ask you to provide us with the necessary information to complete the inspection. If you do not provide the required information, we reserve the right to freeze or close your account until you have provided the requested information.
5.13. We may terminate your account, with or without prior notice to you, if you violate these Terms, or if we have reasonable suspicion that you have done so. In this case, account will be closed, all your earnings and winnings will be cancelled and recovered from your account balance.
5.14. Providing false and/or non-existent and/or misleading information by the user in the moment of registration and during the term of validity of this agreement is considered a gross violation of these Terms and is considered a fraud on the part of the User by Onecoinbuy. In this case, or if we have a reasonable suspicion that you did so, we may with or without prior notice to you terminate your account without any compensation from us.

6. Your Account 
6.1. Accounts could use several currencies, in this case, all Account balances and transactions appear in the currency used for the transaction. 
6.2. We may close or suspend an Account if you are not or we reasonably believe that you are not complying with these Terms, or to ensure the integrity or fairness of the Service or if we have other reasonable grounds to do so. We may not always be able to give you prior notice. If we close or suspend your Account due to you not complying with these Terms, we may cancel and/or void any of your deals and withhold any funds in your account (including the deposit). 
6.3. We reserve the right to close or suspend any Account without prior notice and without returning all funds. 
6.5. If any amount is mistakenly credited to your Account it remains our property and when we become aware of any such mistake, we shall notify you and the amount will be withdrawn from your Account. 
6.6. If for any reason, your Account goes overdrawn, you shall be in debt to us for the amount overdrawn. 
6.7. You must inform us as soon as you become aware of any errors with respect to your Account. In the event we deem in our sole discretion that you have been taking unfair advantage of any bonuses or have executed any other act in bad faith in relation to a bonus, promotion offered on the Website, we shall have the right to block or terminate your account and, in such circumstances, we shall be under no obligation to refund you any funds that may be in your Account. 
6.9. You cannot transfer, sell, or pledge Your Account to another person. This prohibition includes the transfer of any assets of the value of any kind, including but not limited to ownership of accounts, winnings, deposits, deals, rights, and/or claims in connection with these assets, legal, commercial, or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation, and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, foundation, and/or association in any way shape or form 
6.10. If you wish to close your account with us, please send an email to our customer support department from your registered email address on our website.
6.11. Onecoinbuy keeps the data on the sell/buy deal history for not longer than 30 days. 
6.12. When the User makes a request for the account closure, the User accepts the fact that their account is not going to be ever unlocked and the entire history of such an account will be permanently deleted from the Onecoinbuy database. It is strongly advised to withdraw all of your funds before requesting closure of the account.

7. Inactive Accounts Onecoinbuy does not block or suspend Inactive accounts (an account that has not been used for a long period of time). 
8. Deposit of Funds 
8.1. Platform has a minimum deposit amount for every currency. Sending any amount below the minimum will result in funds loss and is not refundable. The maximum deposit amount is not limited. Onecoinbuy doesn't have any fee for deposits. Some of the deposits may take some time related to the network hash rate. 
8.2. Fees and charges may apply to customer deposits and withdrawals, which can be found on the Website. In most cases, we absorb transaction fees for deposits to your Onecoinbuy Account. You are responsible for your own wallet charges that you may incur due to depositing funds with us. 
8.3. You agree to fully pay any and all payments and charges due to us or to payment providers in connection with your use of the Service. You further agree not to make any charge-backs or renounce or cancel or otherwise reverse any of your deposits, and in any such event you will refund and compensate us for such unpaid deposits including any expenses incurred by us in the process of collecting your deposit, and you agree that any winnings from wagers utilizing those charged back funds will be forfeited. 
8.4. Funds originating from criminal and/or illegal and/or unauthorized activities must not be deposited to the Site. 
8.5. We reserve the right to forfeit any positive balance on your Account in case of reset of the wager or any actions that belong to duplicate accounts, conspirations, frauds, criminal activity, or fakes. 
8.6. We do not offer refunds for deposits made in Onecoinbuy, except provided herein. By depositing money, you agree not to make any charge-backs, reversals, refunds, or otherwise cancel any deposits into your Account, and agree to refund and compensate us for unpaid deposits.

9. Withdrawal of Funds 
9.1. Due to the workflow of our anti-fraud system, the waiting time for your withdrawal can be prolonged up to the point when all the needed procedures will be conducted to supply a decent level of financial security for users (up to seven (7) days and more if needed). 
9.2. Please note that we cannot guarantee the successful processing of withdrawals or refunds in the event you break the rules stated in our Terms and Conditions. 
9.3. The responsibility for withdrawing the funds lies with the User only and the Site does not refund the funds that may occur as lost funds in case the receiving wallet/platform doesn’t accept transactions from smart-contract, any definite coins, or in case the user chose the wrong chain of transfer. 
9.4. We reserve the right to change the withdrawal fee at any time without prior notice. 
9.5. You confirm your understanding that if use currencies and/or win currencies that have low liquidity on most of the crypto exchanges or are presented in small quantities on the crypto market and want to withdraw it, we reserve the right to stop this withdrawal for conversion into USDT.
The list of such currencies is not permanent and may constantly change. Therefore, each decision is made on a case-by-case basis. The decision to convert a specific currency is made at the discretion of Onecoinbuy and cannot be appealed by the user.
You acknowledge your understanding that in this case, transactions may take longer to be processed than these terms allow. In any case, such transaction processing time cannot be longer than 48 hours.

10. Payment Transactions and Processors 
10.1. Before making a deposit the User has to be sure of the correctness of the deposit address and the chain of the token he/she is depositing as well as meeting the minimal amount of deposit to make sure the deposit will be credited and reflected on the balance. In case a mistake in the deposit/withdrawal is done by the user - the responsibility lies on their side only. 
10.2. All transactions made on our site might be checked to prevent money laundering or terrorism financing activity. Suspicious transactions will be reported to the relevant authority. 

11. Errors 
In the event of an error or malfunction of our system or processes, all deals are rendered void. You are under an obligation to inform us immediately as soon as you become aware of any error with the Service. In the event of communication or system errors or bugs or viruses occurring in connection with the Service and/or payments made to you as a result of a defect or error in the Service, we will not be liable to you or to any third party for any direct or indirect costs, expenses, losses or claims arising or resulting from such errors, and we reserve the right to void all games/bets in question and take any other action to correct such errors. 

12. Rules of buy, sell deals, cancellation and refunds 
12.1. The winner of a deal will be determined once all tickets of open deals are fully purchased and sold out by buyers and we will not recognize protested or overturned decisions for wagering purposes. 
12.2. Shall a deal be successfully closed, winner(buyer side) will be charged 5.5% of total earn amount as service charge and seller(deal issuer) will be charged 2.5% of total earn which is consisted of original prize and profit as service charge.
12.2.1 Onecoinbuy’s buyer’s and seller’s service charge is not permanently fixed and may change time to time accordance to the market situation. Shall there be a change of percentage Onecoinbuy will make official notice on website, send notice emails to registered email accounts three months before the change takes place. Such changed rate will be applied to only newly issued deals and will not be applied to the existing deals. 
12.3. If a deal is expired the full amount of funds to issue a deal and purchase tickets will be refunded to user’s account. 
12.4. To encourage active trading within the platform, if an issued deal is cancelled by a seller before it expires, a seller will be charged cancellation of 2.5% of the seller’s original fund ; a buyer will be charged 2% of the total purchase amount. 

13. Communications and Notices 
13.1. All communications and notices to be given under these Terms by you to us shall be sent using a Customer Support form on the Website. 
13.2. All communications and notices to be given under these Terms by us to you shall unless otherwise specified in these Terms, be either posted on the Website and/or sent to the Registered Email Address we hold on our system for the relevant Customer. The method of such communication shall be in our sole and exclusive discretion. 
13.3. All communications and notices to be given under these Terms by either you or us, shall be in writing in the English language and must be given to and from the Registered Email Address in your Account. 
13.4. From time to time, we may contact you by email for the purpose of offering you information about unique promotional offerings, and other information from Onecoinbuy. You agree to receive such emails when you agree to these Terms when registering at the Website. 

14. Matters Beyond Our Control 
We cannot be held liable for any failure or delay in providing the Service due to an event of Force Majeure which could reasonably be considered to be outside our control despite our execution of reasonable preventative measures such as: an act of God; trade or labor dispute; power cut; act, failure or omission of any government or authority; obstruction or failure of telecommunication services; or any other delay or failure caused by a third party, and we will not be liable for any resulting loss or damage that you may suffer. In such an event, we reserve the right to cancel or suspend the Service without incurring any liability.

15. Liability 
15.1. To the extent permitted by applicable law, we will not compensate you for any reasonably foreseeable loss or damage (either direct or indirect) you may suffer if we fail to carry out our obligations under these terms unless we breach any duties imposed on us by law (including if we cause death or personal injury by our negligence) in which case we shall not be liable to you if that failure is attributed to: (I) Your own fault; (II) A third party unconnected with our performance of these terms (for instance problems due to communications network performance, congestion, and connectivity or the performance of your computer equipment); or (III) Any other events which neither we nor our suppliers could have foreseen or forestalled even if we or they had taken reasonable care. As this service is for consumer use only we will not be liable for any business losses of any kind. 
15.2. We strongly recommend that You: (I) Take care to verify the suitability and compatibility of the service with your own computer equipment prior to use; and (II) Take reasonable precautions to protect yourself against harmful programs or devices including through installation of antivirus software. 

16. Participation By Those Under Age 
16.1. If we suspect that you are currently under 18 years or were under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) when you participated in a deal through the Service your Account will be suspended (locked) to prevent further participation or making any withdrawals from your Account. We will then investigate the matter, including whether you have been participating as an agent for, or otherwise on behalf of, of a person under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you). If have found that you: (a) are currently; (b) were under 18 years or below the majority age which applies to you at the relevant time; or (c) have been participating as an agent for or at the behest of a person under 18 years or below the majority age which applies: all earnings currently or due to be credited to your Account will be retained; all earnings from buy and sell through the Service whilst under age must be paid to us on demand (if you fail to comply with this provision we will seek to recover all costs associated with recovery of such sums); and/or any funds deposited in your Account which are not earnings will be returned to you OR retained until you turn 18 years old at our sole discretion. We reserve the right to deduct payment transaction fees from the amount to return, including transaction fees for deposits to your Onecoinbuy account which we covered. 
16.2. This condition also applies to you if you are over the age of 18 years but you are participating within a jurisdiction that specifies a higher age than 18 years.
16.3. In the event we suspect you are in breach of the provisions of this Clause or are attempting to rely on them for a fraudulent purpose, we reserve the right to take any action necessary in order to investigate the matter, including informing the relevant law enforcement agencies. 

17.  Anti-fraud policy 
17.1. Onecoinbuy prohibits and rejects the use of the Service for any form of illicit activity, including money laundering, terrorist financing, or trade sanctions violations. Accounts suspected of Money Laundering by using the Service may be blocked, and transactions may be stopped until the Risk department finishes all the required procedures. 
17.2. Know your Customer (“KYC”) Onecoinbuy reserves the right, at any time, to ask for any KYC documentation if it is necessary. Onecoinbuy reserves the right to restrict the Service, deposit, or withdrawal until identity is sufficiently determined, or for any other reason in Onecoinbuy Risk Department discretion. Onecoinbuy defined 7 days’ first response as eligible during the KYC procedure. The full procedure should be done in no more than 30 days after the first response from the Risk department. In case of fraud suspicion and if the user declines the requested information from the Risk department or the eligible timeline will pass, Onecoinbuy reserves the right to close the access for the user's account permanently. 
17.2.1. KYC Procedures may include any of the following: requesting personal information about the User in order to identify him/her, which may include requesting a valid  identification document and a selfie; verifying the personal information provided by the User in the moment of registration and during use of the Service; requesting the information and documents about the User’s occupation, source of funds and source of income used by the  User in the Services; requesting personal information about the User and about the location and jurisdiction from which the User uses the Services in order to that he/she confirmed that he/she not use our services while located in any jurisdiction that prohibits Onecoinbuy’s service ; checking the personal and professional information about the User; checking and analyzing the buy and sell activity pattern of the User; any other information which Onecoinbuy deems necessary to determine the identity and location of a User.
17.2.2. All information and documents that Onecoinbuy receives from the User are strictly confidential. Onecoinbuy takes all measures within its power to securely store such information and prevent its leakage. When we receive your information and documents, we will take reasonable steps to protect your personal information from misuse, loss, and unauthorized access, modification, and disclosure including by using password protected systems and databases, and as well as using cryptographic encryption. You can request the deletion of your personal data addressed to our support team.
17.2.3. All information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to other countries, which may have data protection laws that are different from the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable laws. We store the personal information for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws. We will destroy or permanently de-identify the Personal Information we hold when it is no longer required for any purpose including our legal or operational obligations.
17.3. We will seek criminal and contractual sanctions against any Customer involved in fraud, dishonesty, or criminal acts. We will withhold payment to any Customer where any of these are suspected. The Customer shall identify and shall be liable to pay to us on demand all costs, charges, or losses sustained or incurred by us (including any direct, indirect, or consequential losses, loss of profit, loss of business, and loss of reputation) arising directly or indirectly from the Customer’s fraud, dishonesty or criminal act. 

18. Intellectual Property 
18.1. Any unauthorized use of our name and logo may result in legal action being taken against you.
18.2. As between us and you, we are the sole owners of the rights in and to the Service, our technology, software, and business systems (the "Systems") as well as our odds. you must not use your personal profile for your own commercial gain (such as selling your status update to an advertiser); and when selecting a nickname for your Account we reserve the right to remove or reclaim it if we believe it appropriate. 
18.3. You may not use our URL, trademarks, trade names and/or trade dress, logos ("Marks"), and/or our odds in connection with any product or service that is not ours, that in any manner is likely to confuse Customers or in the public or that in any manner disparages us. 
18.4. Except as expressly provided in these Terms, we and our licensors do not grant you any express or implied rights, license, title, or interest in or to the Systems or the Marks and all such rights, license, title, and interest specifically retained by us and our licensors. You agree not to use any automatic or manual device to monitor or copy web pages or content within the Service. Any unauthorized use or reproduction may result in legal action being taken against you. 

19. Your License 
19.1. Subject to these Terms and your compliance with them, we grant to you a non-exclusive, limited, non-transferable, and non-sublicensable license to access and use the Service for your personal non-commercial purposes only. Our license to you terminates if our agreement with you under these Terms ends. 
19.2. Save in respect of your own content, you may not under any circumstances modify, publish, transmit, transfer, sell, reproduce, upload, post, distribute, perform, display, create derivative works from, or in any other manner exploit, the Service and/or any of the content thereon or the software contained therein, except as we expressly permit in these Terms or otherwise on the Website. No information or content on the Service or made available to you in connection with the Service may be modified or altered, merged with other data, or published in any form including for example screen or database scraping and any other activity intended to collect, store, reorganize or manipulate such information or content. 
19.3. Any non-compliance by you with this Clause may also be a violation of our or third parties’ intellectual property and other proprietary rights which may subject you to civil liability and/or criminal prosecution. 
20. Links to Other Websites 
The Service may contain links to third-party websites that are not maintained by, or related to, us, and over which we have no control. Links to such websites are provided solely as a convenience to Customers and are in no way investigated, monitored, or checked for accuracy or completeness by us. Links to such websites do not imply any endorsement by us of, and/or any affiliation with, the linked websites or their content or their owner(s). We have no control over or responsibility for the availability nor their accuracy, completeness, accessibility, and usefulness. Accordingly, when accessing such websites we recommend that you take the usual precautions when visiting a new website including reviewing their privacy policy and terms of use. 
21. Complaints 
21.1. If you have any concerns or questions regarding these Terms you should contact our Customer Service Department via the links on the Website and use your Registered Email Address in all communication with us. If a user has a complaint you should write an email regarding a dispute to our customer service team at support@onecoinbuy.com 
21.2. Notwithstanding the foregoing, we take no liability whatsoever to you or to any third party when responding to any complaint that we received or took action in connection therewith. 
21.3. In the event of a dispute arising between you and us our Customer Service Department will attempt to reach an agreed solution. Should our Customer Service Department be unable to reach an agreed solution with you, the matter will be escalated to our management. 
22. Assignment 
Neither these Terms nor any of the rights or obligations hereunder may be assigned by you without the prior written consent of us, which consent will not be unreasonably withheld. We may, without your consent, assign all or any portion of our rights and obligations hereunder to any third party provided such third party is able to provide a service of substantially similar quality to the Service by posting written notice to this effect on the Service. 
24. Severability
In the event that any provision of these Terms is deemed by any competent authority to be unenforceable or invalid, the relevant provision shall be modified to allow it to be enforced in line with the intention of the original text to the fullest extent permitted by applicable law. The validity and enforceability of the remaining provisions of these Terms shall not be affected. 
25. Breach of These Terms 
25.1. Without limiting our other remedies, we may suspend or terminate your Account and refuse to continue to provide you with the Service, in either case without giving you prior notice, if, in our reasonable opinion, you breach any material term of these Terms. Notice of any such action taken will, however, be promptly provided to you.
25.2. You agree to fully indemnify, defend and hold harmless Onecoinbuy and its shareholders, directors, agents, and employees from and against all claims, demands, liabilities, damages, losses, costs, and expenses, including legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of: (i) your breach of this Agreement, in whole or in part; (ii) violation by you of any law or any third party rights; and (iii) use by you of the Service. 
26. General Provisions 
26.1. Term of agreement. These Terms shall remain in full force and effect while you access or use the Service or are a Customer or visitor of the Website. These Terms will survive the termination of your Account for any reason. 
26.2. Gender. Words importing the singular number shall include the plural and vice versa, words importing the masculine gender shall include the feminine and neuter genders and vice versa and words importing persons shall include individuals, partnerships, associations, trusts, unincorporated organizations, and corporations. 
26.3. Waiver. No waiver by us, whether by conduct or otherwise, of a breach or threatened breach by you of any term or condition of these Terms shall be effective against, or binding upon, us unless made in writing and duly signed by us, and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived. The failure of us to enforce at any time any term or condition of these Terms shall not be construed to be a waiver of such provision or of the right of us to enforce such provision at any other time. 
26.4. Acknowledgement. By hereafter accessing or using the Service, you acknowledge having read, understood, and agreed to each and every paragraph of these Terms. As a result, you hereby irrevocably waive any future argument, claim, demand, or proceeding to the contrary of anything contained in these Terms. 
26.5. Language. In the event of there being a discrepancy between the English language version of these rules and any other language version, the English language version will be deemed to be correct. 
26.6. Entire agreement. These Terms constitute the entire agreement between you and us with respect to your access to and use of the Service and supersede all other prior agreements and communications, whether oral or written with respect to the subject matter hereof.`,







`PRIVACY POLICY
We reserve the right to change this Privacy Policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.
If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us to contact@onecoinbuy.com
This Privacy Policy applies only to our online activities and is valid for visitors to our Website with regards to the information that they shared and/or collected at Onecoinbuy.io. This Privacy Policy is not applicable to any information collected offline or via channels other than this website.
Consent 
We ask for your consent to process your information for specific purposes and you have the right to withdraw your consent at any time. However, by using our website, you hereby consent to our Privacy Policy and agree to its terms.
What User Data We Collect
By “personal data” shall be understood any information relating to an identified or identifiable person; an identifiable natural person is the one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, location data, email an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
We may process data enabling us to get in touch with you (“contact data”). The contact data may include your name, email address. The source of the contact data is our registration form, contact form, and information provided in your Account.
We may process your website user account data (“account data”). The account data may include your account identifier, name, email address, account creation and modification dates, website settings. The primary source of the account data is you; some elements of the account data may be generated by our website.
We may process information relating to “technical and financial data, usage data”. Meaning, when you visit our Website and/or use our Services, we may collect technical and financial information about you, which may include: IP address, locale, registration date, log-in dates, associated affiliate, affiliate-related statistics (the source of your traffic to our Site, data on your views and clicks on our promotional materials), self-exclusion dates and periods, ban dates and periods, failed log-in attempts count and time, Account balance, Account bonus funds, Account real money funds, transaction dates, transaction values, transaction identifiers, total bonus amount, total bonus count, total deposit amount, total deposit count, total withdrawal amount, total withdrawal count, pending withdrawal amount, pending withdrawal count, error withdrawal amount, error withdrawal count, deposit/withdrawal ratio, total income, deal session statistics(deal issue, deal cancelled, deal success, profit, tickets bought, deals won, earnings, balance), pages visited, device used, nick name (alias), session identifier, chat data when you communicate with us (your IP address, domain, browser type, operating system type, first name and last name (if nessesary), email, title, username, other information which may be used to identify your transactions with us (operation ID, transaction ID, start date, end date, sum, order number, transaction number, method, status, processing system, payment system and technical parameters of your device and software you use to access our Website.
We may process information contained in or relating to any communication that you send to us or that we send to you (“communication data”). The communication data may include the communication content and metadata associated with the communication. We may generate the metadata associated with communications made using the contact forms. 
Why We Collect Your Data 
Onecoinbuy will collect your Personal Information only by lawful and fair means and not in an intrusive way to operate its business as a licensed online wagering bookmaker for the following purposes: 
For know-your-customer (KYC) identity verification. 
If you wish to subscribe to Onecoinbuy’s marketing communications. 
To process your application to become a Member of Onecoinbuy or any managed subdomains. 
To provide and improve services to you as a Member. 
To identify you as a Member and authenticate your identity for security purposes and comply with our legal obligations. 
To maintain your Member account. 
To upgrade and enhance your experience within the website or over the device, or to tailor or develop information, services, or products to suit your needs which may include market research and conducting promotions. 
To create aggregate data about Members through demographic profiling, statistical analysis of the database to provide to potential and existing stakeholders, and to allow for more efficient operation of Onecoinbuy’s business. We also use this data to analyze User's accounts for the presence of multi-accounting. 
To respond to your questions, comments, or requests. 
To comply with Onecoinbuy’s contractual, legal, and statutory obligations. 
For taking appropriate action if Onecoinbuy has a reason to suspect that unlawful activity or misconduct of a serious nature has been, is being, or may be engaged in that relates to our functions and activities. 
To establish, exercise, or defend any legal claims.
Unless it is required by law, your personal data will not be disclosed to third parties. As Onecoinbuy business partners or suppliers or service providers may be responsible for certain parts of the overall functioning or operation of the Website, personal data may be disclosed to them. The employees of Onecoinbuy have access to your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to such disclosures.
Onecoinbuy is using users’ information for marketing purposes. However, we respect users’ privacy. If users do not wish to receive any promotional materials, they can choose such an option during registration or unsubscribe at any time.
Log Files
Onecoinbuy follows a standard procedure of using log files. These files log visitors when they visit the website. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
Our Use of Cookies
We may place and access certain Cookies on your computer or device. A Cookie is a piece of information in the form of a very small text file that is placed on an internet user's hard drive. It is generated by a web page server, which is basically the computer that operates a website. The information the Cookies contain is set by the server and it can be used by that server whenever the user visits the site.
By using our Website, you agree to the use and storage of Cookies on your computer or device. If you do not wish our Website to use Cookies on your computer or device, you can at any time deactivate and delete Cookies through your browser settings. More information on Cookies and how to disable and delta them may be found at https://www.aboutCookies.org/Cookie-faq or http://www.allaboutcookies.org. Please note, however, that if you refuse or delete Cookies, certain portions of our Website may not function properly, and we may not be able to offer you certain services. 
Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.
We use the following cookies: 
“Necessary” cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies. 
“Preferences” cookies enable a website to remember information that changes the way the website behaves or looks, like the region that you are in. 
„Statistics” cookies help the website to understand how visitors interact with websites by collecting and reporting information anonymously. 
“Security” cookies are used as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials and protecting our website and services generally. 
“Authentication and status” cookies are used to identify you when you visit our website and as you navigate our website, and to help us determine if you are logged into our website. 
“Marketing” cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user, and thereby more valuable for publishers.
We use both first-party and third-party Cookies. First-party Cookies are those placed directly by Us and are used only by us. We use Cookies to facilitate and improve your experience of the Website and to provide and improve our products and services. By using our Website, you may also receive certain third-party Cookies on your computer or device. Third-party Cookies are those placed by websites, services, and/or parties other than us. In addition, we use analytics services, which also use Cookies. Website analytics refers to a set of tools used to collect and analyze usage statistics, enabling us to better understand how people use the Website.
Safeguarding and Securing the Data
Onecoinbuy is committed to securing your data and keeping it confidential. Onecoinbuy has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.
Third-Party Privacy Policies
Our Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.
Data transfers 
We maintain servers around the world and your information may be processed on servers located outside of the country where you live. Data protection laws vary among countries, with some providing more protection than others. Regardless of where your information is processed, we apply the same protections described in this Policy. We also comply with certain legal frameworks relating to the transfer of data. 
Retaining and deleting personal data 
Our Policy and procedure are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data. Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes. Notwithstanding the other provisions, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. 
Your rights 
In particular, you have the right to do the following: 
Withdraw your consent at any time. You have the right to withdraw consent where you have previously given consent to the processing of your personal data. 
Object to processing of your data. You have the right to object to the processing of your data if the processing is carried out on a legal basis other than consent. 
Access your data. You have the right to learn if Data is being processed, obtain disclosure regarding certain aspects of the processing, and obtain a copy of the Data undergoing processing.
Verify and seek rectification. You have the right to verify the accuracy of your data and ask for it to be updated or corrected. 
Restrict the processing of your Data. You have the right, under certain circumstances, to restrict the processing of your data. In this case, we will not process your data for any purpose other than storing it. 
Have your personal data deleted or otherwise removed. You have the right, under certain circumstances, to obtain the erasure of your data from us. 
The right to data portability. You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions. 
Lodge a complaint. You have the right to bring a claim before your competent data protection authority ; request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers ; request that a business deletes any personal data about the consumer that a business has collected ; request that a business that sells a consumer’s personal data, not sell the consumer’s personal data. If you would like to exercise any of these rights, please contact us at support@onecoinbuy.com
Restricting the Collection of your Personal Data
At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following: When you are filling the forms on the website, make sure to check if there is a box that you can leave unchecked, if you don't want to disclose your personal information.
Onecoinbuy will not lease, sell or distribute your personal information to any third parties unless we have your permission. We might do so if the law forces us. Your personal information will be used when we need to send you promotional materials if you agree to this privacy policy. 
Changes to our Privacy Policy 
We may change this Privacy Policy from time to time (for example, if the law changes). Any changes will be immediately posted on our Website and you will be deemed to have accepted the terms of the Privacy Policy on your first use of the Website following the alterations. We recommend that you check this page regularly to keep up-to-date. In the event that you do not agree with any changes, we recommend that you contact customer support for clarifying or otherwise close Your Account.`

],
        }
    },
    computed:{
        title(){
            let _title = '';
            switch (this.idx) {
                case 0:
                    _title = 'Terms of Service'
                    break;

                case 1:
                    _title = 'Privacy policy'
                    break;
            
                default:
                    break;
            }
            return _title;
        }
    }
}
</script>

<style scoped>
    pre{
        white-space: pre-line;
        word-break: break-all;
    }
</style>


<style scoped>
.pointer {
    cursor: pointer;
}
</style>
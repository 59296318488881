import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/deal/list/closed',
    name: 'DealListClose',
    component: () => import(/* webpackChunkName: "deal" */ '../views/deal/DealClose.vue')
  },
  {
    path: '/deal/list/open',
    name: 'DealListOpen',
    component: () => import(/* webpackChunkName: "deal" */ '../views/deal/DealOpen.vue')
  },
  {
    path: '/deal/list/open/:code',
    name: 'DealListOpenCode',
    component: () => import(/* webpackChunkName: "deal" */ '../views/deal/DealOpenFilter.vue')
  },
  {
    path: '/deal/list/hot',
    name: 'DealListHot',
    component: () => import(/* webpackChunkName: "deal" */ '../views/deal/DealHot.vue')
  },
  {
    path: '/upload-ticket',
    name: 'UploadTicket',
    component: () => import(/* webpackChunkName: "deal" */ '../views/deal/UploadTicket.vue')
  },
  
// board

  {
    path: '/notice',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "board" */ '../views/board/Notice.vue')
  },
  {
    path: '/notice/:code',
    name: 'NoticeDetail',
    component: () => import(/* webpackChunkName: "board" */ '../views/board/NoticeDetail.vue')
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import(/* webpackChunkName: "board" */ '../views/board/Event.vue')
  },
  {
    path: '/event/:code',
    name: 'EventDetail',
    component: () => import(/* webpackChunkName: "board" */ '../views/board/EventDetail.vue')
  },
  
// mypage
  {
    path:'/serviceguideEn',
    name: 'Service',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/ServiceGuide.vue'),
  },
  {
    path:'/serviceguideKor',
    name: 'ServiceKR',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/ServiceGuideKR.vue'),
  },
  {
    path: '/deal/detail/:idx',
    name: 'DealDetail',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/deal/DealDetail.vue'),
    props: true
  },
  {
    path: '/deal/detail/:code/winner',
    name: 'WinnerDetail',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/deal/WinnerDetail.vue'),
  },
  {
    path: '/mypage',
    name: 'Mypage',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Mypage.vue')
  },
  {
    path: '/mypage/edit-profile',
    name: 'EditProfile',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/EditProfile.vue')
  },
  {
    path: '/mypage/inquiry',
    name: 'Inquiry',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Inquiry.vue')
  },
  {
    path: '/mypage/inquiry/add',
    name: 'InquiryAdd',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/InquiryCreate.vue')
  },
  {
    path: '/mypage/inquiry/detail/:code',
    name: 'InquiryDetail',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/InquiryDetail.vue')
  },
  {
    path: '/mypage/personal-info-setting',
    name: 'PersonalInfoSetting',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/PersonalInfoSetting.vue')
  },
  {
    path: '/mypage/delete-account',
    name: 'DeleteAccount',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/DeleteAccount.vue')
  },
  {
    path: '/referral-code',
    name: 'ReferralCode',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/ReferralCode.vue')
  },
  {
    path: '/ocb-swap',
    name: 'OcbSwap',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/OcbSwap.vue')
  },
  {
    path: '/landing/:code',
    name: 'UserLanding',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/user/Landing.vue')
  },
  // {
  //   path: '/user/wish/:code',
  //   name: 'UserLanding',
  //   component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/UserLanding.vue')
  // },

  {
    path: '/user',
    name: 'UserDeals',
    component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/Deals.vue'),
    children: [
      {
        path: '/user/buy/wish-list/:code',
        name: 'UserWishList',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/WishList.vue')
      },
      {
        path: '/user/buy/participate/:code',
        name: 'UserParticipate',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/Participate.vue')
      },
      {
        path: '/user/buy/won/:code',
        name: 'UserWon',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/Won.vue')
      },
      {
        path: '/user/buy/closed/:code',
        name: 'UserClosed',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/Closed.vue')
      },
      {
        path: '/user/sell/sale/:code',
        name: 'UserSale',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/Sale.vue')
      },
      {
        path: '/user/sell/soldout/:code',
        name: 'UserSoldOut',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/SoldOut.vue')
      },
      {
        path: '/user/sell/cancel/:code',
        name: 'Cancel',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/user/Cancel.vue')
      },
    ]
  },

  // my deal
  {
    path: '/mydeals',
    name: 'MyDeals',
    component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/MyDeals.vue'),
    children: [
      {
        path: '/mydeal/buy/wish-list',
        name: 'WishList',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/WishList.vue')
      },
      {
        path: '/mydeal/buy/participate',
        name: 'BuyParticipate',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/Participate.vue')
      },
      {
        path: '/mydeal/buy/won',
        name: 'Won',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/Won.vue')
      },
      {
        path: '/mydeal/buy/closed',
        name: 'Closed',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/Closed.vue')
      },
      {
        path: '/mydeal/sell/sale',
        name: 'Sale',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/Sale.vue')
      },
      {
        path: '/mydeal/sell/soldout',
        name: 'SoldOut',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/SoldOut.vue')
      },
      {
        path: '/mydeal/sell/cancel',
        name: 'Cancel',
        component: () => import(/* webpackChunkName: "mydeal" */ '../views/mydeal/Cancel.vue')
      },
    ]
  },

// transaction
  {
    path: '/transaction/deposit/:symbol',
    name: 'Deposit',
    component: () => import(/* webpackChunkName: "transaction" */ '../views/transaction/Deposit.vue')
  },
  {
    path: '/transaction/withdrawal/:symbol',
    name: 'Withdrawal',
    component: () => import(/* webpackChunkName: "transaction" */ '../views/transaction/Withdrawal.vue')
  },
  {
    path: '/transaction/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "transaction" */ '../views/transaction/History.vue')
  },

  //sign
  {
    path: '/signin',
    name: 'Signin',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/Signin.vue')
  },
  {
    path: '/signup/:code',
    name: 'SigninCode',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/Signup.vue')
  },
  {
    path: '/reset-pw/:code',
    name: 'ResetPw',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/ResetPw.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/Signup.vue')
  },
  {
    path: '/signup/confirm-email',
    name: 'SConfirmEmail',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/ConfirmEmail.vue')
  },
  {
    path: '/change-pw',
    name: 'CConfirmPasswordEmail',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/ConfirmPasswordEmailLogout.vue')
  },
  {
    path: '/reset-pw',
    name: 'SConfirmPasswordEmail',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/ConfirmPasswordEmail.vue')
  },
  {
    path: '/signup/set-profile/:code',
    name: 'SetProfile',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SetProfile.vue')
  },
  {
    path: '/mailing/join/:code',
    name: 'CertificationJoin',
    component: () => import(/* webpackChunkName: "Certification" */ '../views/mailing/Join.vue')
  },
  {
    path: '/mailing/password/:code',
    name: 'CertificationPassword',
    component: () => import(/* webpackChunkName: "Certification" */ '../views/mailing/Password.vue')
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/ConfirmEmail.vue')
  },
  {
    path:'/banner',
    name:'Banner',
    component: () => import(/* webpackChunkName: "banner" */ '../views/Banner.vue')
  }
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router

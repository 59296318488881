import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token : '',
    profile : '',
    login :false,
    item: {
      idx: 0,
      stop_selling: false,
      userType: '',
      title: '',
      avatar: '',
      seller: '',
      coin: '',
      price: 0,
      total: 0,
      participate: 0,
      totalNo: 0,
      wishList: false,
      expiryDate: new Date(),
      winner: {
        status: false,
        nickname: '',
        uid: '',
        deal_number: '',
        ticket_quantity: '',
        result_time: '',
        ticket_num: ''
      }
    }
  },
  plugins: [createPersistedState()],
  mutations: {
    updateItem(state, newItem) {
      state.item = newItem;
    },
    Login(state, mem) {
      state.token = mem.info.token;
      state.profile = mem.info.profile;
      state.login = true;
    },
    ChangeProfile(state, mem) {
      state.profile = mem.info.profile;
    },
    LOGOUT (state){
      state.login = false;
      state.profile = '';
      state.token = '';
    }
  },
  actions: {
    SETLOGIN({commit},mem) {
      commit('Login', mem)
    },
    CHANGEPROFILE({commit},mem) {
      commit('ChangeProfile', mem)
    },
    logout({ commit }) {
      commit('LOGOUT')
    },
  },
  modules: {
  }
})

<template>
    <div>
      <div class="bg-white rounded-3 mx-3 px-3 py-px-12 mt-3 flex-start-center position-relative notice_box">
        <img src="@/assets/img/layout_icon/sound_red.svg" alt="notice" width="20" class="me-2 pe-1">
        <div class="txt_wrap overflow-hidden" >
            <transition name="slide">
                <div class="notice-container" :key="current_notice_idx" v-if="show_notice">
                    <span class="fs-px-13 fw-400">
                        {{ current_notice }}
                    </span>
                </div>
            </transition>
        </div>
      </div>
    </div>
</template>
<script>
const CryptoJS = require("crypto-js");

export default {
    data(){
        return{
            show_notice: true,
            current_notice_idx: 0,
            notice_msg: [],
        }
    },
    computed: {
        current_notice() {
        return this.notice_msg[this.current_notice_idx];
        }
    },
    mounted() {
        this.GetNoticeList()
        
    },
    methods: {
        GetNoticeList(){
            const status = 'E';
            const body = {status};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/main/GetNoticeList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.notice_msg = body.list;
                            setInterval(() => {
                                this.change_notice();
                            }, 3000);
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
            
        },
        change_notice() {
        this.show_notice = false;
        setTimeout(() => {
            this.current_notice_idx++;
            if (this.current_notice_idx >= this.notice_msg.length) {
            this.current_notice_idx = 0;
            }
            this.show_notice = true;
        }, 300);
        }

    }
}
</script>
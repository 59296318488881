import AlertTemp from '@/components/other/AlertTemp.vue';

export default{
    install(Vue){ 
        let alert_list = [];
        
        function createAlertInstance(msg, is_confirm, okFn, cancelFn) {
            let idx = alert_list.length > 0 ? alert_list.length : 0;
            const AlertComponent = Vue.extend(AlertTemp);
            const AlertInstance = new AlertComponent({
                propsData: {
                    idx: idx,
                    msg: msg,
                    is_confirm: is_confirm
                },
                methods: {
                    ok() {
                        okFn && okFn();
                        alert_off(idx);
                    },
                    cancel() {
                        cancelFn && cancelFn();
                        alert_off(idx);
                    }
                }
            });
            alert_list.push({idx: idx,item:AlertInstance});
            AlertInstance.$mount();
            document.querySelector('#app').appendChild(AlertInstance.$el);
        }
        
        Vue.prototype.$alert = function(msg) {
            return new Promise((resolve, reject) => {
                createAlertInstance(msg, false, resolve);
            });
        };
        
        Vue.prototype.$confirm = function(msg) {
            return new Promise((resolve, reject) => {
                createAlertInstance(msg, true, () => resolve({is_ok: true}), () => resolve({is_cancel: true}));
            });
        };

        function createPpInstance(msg, is_confirm, ok_btn_txt, cancel_btn_txt, add_class, okFn, cancelFn, auto_hide) {
            let idx = alert_list.length > 0 ? alert_list.length : 0;
            const AlertComponent = Vue.extend(AlertTemp);
            const AlertInstance = new AlertComponent({
                propsData: {
                    idx: idx,
                    msg: msg,
                    is_confirm: is_confirm,
                    ok_btn_txt: ok_btn_txt,
                    cancel_btn_txt: cancel_btn_txt,
                    add_class: add_class,
                    auto_hide: auto_hide
                },
                methods: {
                    ok() {
                        okFn && okFn();
                        alert_off(idx);
                    },
                    cancel() {
                        cancelFn && cancelFn();
                        alert_off(idx);
                    }
                }
            });
            alert_list.push({idx: idx,item:AlertInstance});
            AlertInstance.$mount();
            document.querySelector('#app').appendChild(AlertInstance.$el);

            if (auto_hide) {
                setTimeout(() => {
                  alert_off(idx);
                }, 1500);
            }
        }
        
        Vue.prototype.$pp = function(obj) {
            return new Promise((resolve, reject) => {
                const msg = obj.msg;
                const is_confirm = obj.is_confirm;
                const ok_btn_txt = obj.ok_btn_txt;
                const cancel_btn_txt = obj.cancel_btn_txt;
                const add_class = obj.add_class;
                const auto_hide = obj.auto_hide || false;

                createPpInstance(msg, is_confirm, ok_btn_txt, cancel_btn_txt, add_class, () => resolve({is_ok: true}), () => resolve({is_cancel: true}), auto_hide);
            });
        };
        
        function alert_off(idx){
            const _idx = alert_list.findIndex(item => item.idx === idx);
            const alertInstance = alert_list[_idx].item;
            alert_list.splice(_idx, 1);
            alertInstance.$destroy();
            alertInstance.$el.remove();
        }

        
        // 예시
        
        // ==========Alert
        // this.$alert("<h5 class='pb-3'> Title </h5> some msg").then( ()=>{
        // } )


        // ==========Confirm
        // this.$confirm("some msg").then( (result)=>{
        //     if(result.is_ok){}
        //     else if(result.is_cancel){}
        // })


        // ==========Custom
        // this.$pp({
        //     msg: 'some msg', 
        //     is_confirm: true, 
        //     ok_btn_txt: 'yes text', 
        //     cancel_btn_txt: 'cancel text'
        // }).then( (result)=>{
        //     if(result.is_ok){}
        //     else if(result.is_cancel){}
        // })
    }
}
